<template>
  <div>
    <!-- :class="{
        ' ': isSidebarOpen,
        ' ': !isSidebarOpen,
      }" -->
    <aside
      :class="`${asideClass} ${
        isSidebarOpen
          ? 'translate-x-0 md:translate-x-[-100%]'
          : 'translate-x-[-100%]'
      }`"
      class="z-[6] max-w-[270px] w-[270px] duration-100 fixed top-0 bottom-0 border-[#6670851f] border-r-[1px] border-l-[1px] mt-[83px] flex flex-col bg-white"
    >
      <slot></slot>
    </aside>
  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "Header",
  data() {
    return {};
  },
  props: {
    asideClass: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapState({
      isSidebarOpen: (state) => state.dashboard.isSidebarOpen,
    }),
  },
  methods: {
    ...mapMutations("dashboard", ["toggleSidebar"]),
  },
  watch: {
    $route: {
      handler() {
        if (this.isSidebarOpen) {
          this.toggleSidebar();
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
