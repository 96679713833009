import axios from "axios";

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

// make sure credentials are sent with every request

instance.defaults.withCredentials = true;
export default instance;
