<template>
  <div class="bg-[#060A23] font-polysans">
    <div>
      <Header />

      <section class="max-w-[1200px] p-[1rem] mx-auto pt-[5rem]">
        <h2
          class="text-white font-polysans text-center font-bold md:text-[40px] md:leading-[49.76px] text-[22px] leading-[124.4%]"
        >
          Revolutionize Your
          <span class="text-[#32D583]">Football Betting</span> Experience with
          our Accurate Tips and Predictions.
        </h2>
        <p
          class="opacity-[0.6700000166893005] text-[white] text-center mt-[2rem] md:text-[15px] text-[13px] max-w-[990px] mx-auto"
        >
          Are you tired of losing your bets on football games? Do you want to
          improve your winning chances and earn more money? Look no further than
          EasyWin. Our platform provides you with the most accurate tips and
          predictions for top football leagues and cups, based on the latest
          team performances and statistics.
        </p>
        <a
          href="https://t.me/+3UimQRWQajcwZTdk"
          rel="noreferrer noopener"
          target="_blank"
          class="flex btn-animate items-center border-[#ffffff0d] bg-[#ffffff14] justify-center gap-[10px] rounded-[25px] border-[1px] text-white mx-auto mt-[2rem] px-[15px] py-[15px] w-max"
        >
          <span>Join our Telegram Channel</span>
          <span
            ><svg
              width="22"
              height="21"
              viewBox="0 0 22 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.9982 21.0164C16.8017 21.0164 21.5064 16.3117 21.5064 10.5082C21.5064 4.70467 16.8017 0 10.9982 0C5.19466 0 0.48999 4.70467 0.48999 10.5082C0.48999 16.3117 5.19466 21.0164 10.9982 21.0164Z"
                fill="url(#paint0_linear_820_50073)"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.23668 10.3937C8.29747 9.05155 10.3435 8.18405 11.3746 7.75849C14.2881 6.54727 14.8937 6.33448 15.2866 6.31812C15.3684 6.31812 15.5648 6.33448 15.6958 6.43269C15.794 6.51453 15.8267 6.62911 15.8431 6.71094C15.8594 6.79278 15.8758 6.97283 15.8594 7.12014C15.6958 8.7733 15.0247 12.8162 14.6646 14.6657C14.5173 15.4514 14.2226 15.7133 13.9444 15.746C13.3388 15.7951 12.8641 15.3368 12.2749 14.9604C11.3583 14.3547 10.8345 13.9783 9.93426 13.389C8.90308 12.7016 9.57417 12.3251 10.1634 11.7195C10.3107 11.5558 13.0114 9.11702 13.0605 8.88787C13.0605 8.85514 13.0769 8.75693 13.0114 8.70783C12.946 8.65872 12.8641 8.67509 12.7986 8.69146C12.7004 8.70783 11.2273 9.6899 8.36294 11.6213C7.93738 11.9159 7.56092 12.0469 7.21719 12.0469C6.84073 12.0469 6.12054 11.8341 5.5804 11.654C4.92569 11.4413 4.40192 11.3267 4.45102 10.9666C4.48375 10.7865 4.74564 10.5901 5.23668 10.3937Z"
                fill="white"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_820_50073"
                  x1="0.48999"
                  y1="10.5006"
                  x2="21.4911"
                  y2="10.5006"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#2AABEE" />
                  <stop offset="1" stop-color="#229ED9" />
                </linearGradient>
              </defs>
            </svg>
          </span>
        </a>
      </section>
      <section class="predictions-section mt-[5rem]">
        <h4
          class="text-white font-semibold mx-auto md:text-[32px] md:leading-[44.29px] text-center mb-[2rem] text-[20px] leading-[27.68px]"
        >
          Free Punter’s Pick
        </h4>
        <div class="overflow-x-auto mx-auto max-w-[1200px] px-[1rem]">
          <div
            class="header bg-[#1A1E35] text-white p-[1rem] flex items-center gap-[10px] w-full"
          >
            <button
              class="bg-white duration-300 text-[#344054] text-inter rounded-[25px] font-semibold md:text-[14px] text-[12px] leading-[20px] p-[5px] md:p-[10px]"
              :class="`${
                activeSelection === 'yesterday'
                  ? '!bg-[#32D583] text-white'
                  : ''
              }`"
              @click="handleSelectionChange('yesterday')"
            >
              Yesterday
            </button>
            <button
              class="bg-white duration-300 text-[#344054] text-inter rounded-[25px] font-semibold md:text-[14px] text-[12px] leading-[20px] p-[5px] md:p-[10px]"
              :class="`${
                activeSelection === 'today' ? '!bg-[#32D583] text-white' : ''
              }`"
              @click="handleSelectionChange('today')"
            >
              Today
            </button>
            <button
              class="bg-white duration-300 text-[#344054] text-inter rounded-[25px] font-semibold md:text-[14px] text-[12px] leading-[20px] p-[5px] md:p-[10px]"
              :class="`${
                activeSelection === 'tomorrow' ? '!bg-[#32D583] text-white' : ''
              }`"
              @click="handleSelectionChange('tomorrow')"
            >
              Tomorrow
            </button>
          </div>
          <table class="min-w-full bg-white font-poppins">
            <thead class="border-y-[1px] border-[#E4E7EC] bg-[#F9FAFB]">
              <tr class="text-white bg-[#1A1E35] text-[12px] leading-[18px]">
                <th class="py-3 px-1 text-left">Time</th>
                <th class="py-3 px-1 text-left">League</th>
                <th class="py-3 px-1 text-left md:w-auto w-[50%]">Fixture</th>
                <th class="py-3 px-1 text-left">Tip</th>
              </tr>
            </thead>
            <tbody
              class="font-poppins md:text-[14px] text-[8px] leading-[20px] font-bold text-black"
            >
              <!-- Table rows go here -->
              <tr
                v-for="tip in computedFreeTips[activeSelection]"
                :key="tip._id"
              >
                <td
                  class="py-3 md:px-[10px] px-[5px] border-l-0 border-b border-x border-[#E4E7EC] text-left"
                >
                  {{ convertToWATTime(tip.time) }}
                </td>
                <td
                  class="py-3 md:px-[10px] px-[5px] border-b border-x border-[#E4E7EC] text-left"
                >
                  {{ getLeagueAbbreviation(tip.league.name) }}
                </td>
                <td
                  class="py-3 md:px-[10px] px-[5px] border-b border-x border-[#E4E7EC] text-left"
                >
                  {{ tip.teamOne }}
                  <span class="text-[#667085] font-light">vs</span>
                  {{ tip.teamTwo }}
                </td>
                <td
                  class="py-3 md:px-[10px] px-[5px] border-b border-r-0 border-x border-[#E4E7EC] text-left"
                >
                  {{ tip.tip }}
                </td>
              </tr>

              <!-- No items found row -->
              <tr v-if="!computedFreeTips[activeSelection].length">
                <!-- flex w-full items-center text-center bg-white justify-center py-[2rem] px-[1rem] -->
                <td
                  class="md:py-[3rem] py-3 px-4 border-b border-[#E4E7EC] text-center"
                  colspan="4"
                >
                  {{
                    activeSelection === "yesterday"
                      ? "No prediction for yesterday."
                      : "Our team of experts is currently busy with this task. Please check back shortly for updates! 🛠️"
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="flex items-center justify-center mt-[2rem]">
          <button
            title="Coming soon"
            v-tippy="{ placement: 'bottom', arrow: true }"
            class="bg-[#32D583] cursor-not-allowed opacity-50 text-white text-[15px] leading-[18px] px-[2rem] py-[1rem] rounded-[25px]"
          >
            Subscribe to VIP Plan
          </button>
        </div>
      </section>
      <section class="predictions-section my-[5rem]">
        <h4
          class="text-white mx-auto md:text-[32px] md:leading-[44.29px] font-semibold text-center mb-[2rem]"
        >
          Free ACCA Tips
        </h4>
        <div class="overflow-x-auto mx-auto max-w-[1200px] px-[1rem]">
          <table class="min-w-full bg-white font-poppins">
            <thead class="border-b-[1px] border-[#E4E7EC] bg-[#F9FAFB]">
              <tr class="text-white bg-[#1A1E35] text-[12px] leading-[18px]">
                <th class="py-3 px-1 text-left">Odds</th>
                <th class="py-3 px-1 text-left">Bet Code</th>
                <th class="py-3 px-1 text-left">Promo Code</th>
                <th class="py-3 px-1 text-left">Register Here</th>
              </tr>
            </thead>
            <tbody
              class="font-poppins md:text-[14px] text-[8px] leading-[20px] font-bold text-black"
            >
              <!-- Table rows go here -->
              <tr v-for="bookingCode in bookingCodes" :key="bookingCode._id">
                <td
                  class="py-3 md:px-[10px] px-[5px] border-b border-x border-l-0 border-[#E4E7EC] text-left"
                >
                  {{ bookingCode.odds }} odd(s)
                </td>
                <td
                  class="py-3 md:px-[10px] px-[5px] border-b border-x border-[#E4E7EC] text-left"
                >
                  {{ bookingCode.code }}
                </td>
                <td
                  class="py-3 md:px-[10px] px-[5px] border-b border-x border-[#E4E7EC] text-left"
                >
                  {{
                    bookingCode?.bookmaker?.code?.trim()
                      ? bookingCode?.bookmaker?.code
                      : "No promo code"
                  }}
                </td>
                <td
                  class="py-3 md:px-[10px] px-[5px] border-r-0 border-b border-x border-[#E4E7EC] text-left"
                >
                  <a
                    :href="generateLinkFromBookmaker(bookingCode?.bookmaker)"
                    class="w-[32px] h-[32px]"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <img
                      :src="generateLogoFromBookmaker(bookingCode?.bookmaker)"
                    />
                  </a>
                </td>
              </tr>
              <!-- No items found row -->
              <tr v-if="!bookingCodes.length">
                <td
                  class="md:py-[3rem] py-3 px-4 border-b border-[#E4E7EC] text-center"
                  colspan="4"
                >
                  Our team of experts is currently busy with this task. Please
                  check back shortly for updates! 🛠️
                </td>
              </tr>
              <!-- Add more table rows as needed -->
            </tbody>
          </table>
        </div>
        <div class="flex items-center justify-center mt-[2rem]">
          <a
            href="https://twitter.com/easytipster1?s=21&t=8oYoEtyPHG7tL1-h1IgUcg"
            rel="noreferrer noopener"
            target="_blank"
            class="flex btn-animate items-center border-[#ffffff0d] bg-[#ffffff14] justify-center gap-[10px] rounded-[25px] border-[1px] text-white mx-auto mt-[2rem] px-[15px] py-[15px] w-max"
            ><span data-v-5b685826="">Follow us on twitter</span
            ><span data-v-5b685826=""
              ><svg
                width="27"
                height="21"
                viewBox="0 0 27 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M23.7463 5.2279C23.7621 5.45573 23.7621 5.68357 23.7621 5.9135C23.7621 12.9197 18.4387 21 8.70476 21V20.9958C5.82932 21 3.01361 20.1748 0.592957 18.6188C1.01107 18.6691 1.43128 18.6943 1.85253 18.6954C4.23546 18.6975 6.55028 17.8964 8.42497 16.4212C6.16046 16.3782 4.17468 14.8988 3.48097 12.7391C4.27424 12.8924 5.0916 12.8609 5.87019 12.6478C3.40133 12.148 1.62514 9.97465 1.62514 7.45061C1.62514 7.42751 1.62514 7.40546 1.62514 7.38341C2.36077 7.79393 3.18442 8.02177 4.02693 8.04697C1.70164 6.48992 0.984872 3.39051 2.38906 0.967265C5.07588 4.27981 9.04009 6.29358 13.2956 6.50671C12.8691 4.66513 13.4518 2.73535 14.8266 1.44078C16.958 -0.566689 20.3103 -0.463795 22.3139 1.67072C23.499 1.43658 24.635 1.00086 25.6745 0.383501C25.2794 1.61087 24.4526 2.65346 23.3481 3.31597C24.3971 3.19207 25.4219 2.91069 26.3871 2.48127C25.6766 3.548 24.7817 4.47719 23.7463 5.2279Z"
                  fill="#1D9BF0"
                />
              </svg> </span
          ></a>
        </div>
      </section>
      <section class="testimonies text-white mx-auto max-w-[1200px] py-[2rem]">
        <h4
          class="text-white mx-auto md:text-[32px] md:leading-[44.29px] font-semibold text-center"
        >
          Predictions
        </h4>

        <div
          class="flex items-center justify-center flex-wrap mt-[2rem] p-[1rem] gap-[1rem]"
        >
          <router-link
            :to="{ name: 'dashboard' }"
            v-for="singlePredictionType in availablePredictions"
            :key="singlePredictionType"
            class="lg:max-w-[23%] text-[20px] leading-[24px] md:max-w-[31%] sm:max-w-[48%] w-full text-center h-[146px] flex items-center justify-center rounded-[15px] border-[1px] border-[#ffffff0d] bg-[#ffffff14]"
          >
            {{ singlePredictionType }}
          </router-link>
        </div>
      </section>
      <section class="testimonies text-white mx-auto max-w-[1200px] py-[2rem]">
        <h4
          class="text-white mx-auto md:text-[32px] md:leading-[44.29px] font-semibold text-center"
        >
          Testimonies
        </h4>
        <div class="flex mt-[2rem] p-[1rem] gap-[12px] flex-wrap">
          <div
            class="flex flex-col w-full lg:max-w-[50%] rounded-[16px] bg-[#1A1D34] border-[1px] border-[#1A1D34] p-[1rem] md:p-[2rem]"
          >
            <div class="flex w-full items-center justify-between">
              <h3
                class="max-w-[354px] font-semibold md:text-[19px] md:leading-[28px] text-[17px]"
              >
                Consistent Performance: EasyWin Delivers Results Every Time
              </h3>
              <span
                class="bg-white rounded-[6px] p-[5px] gap-[3px] py-[2px] flex items-center text-[#1F2937]"
              >
                <span> 5.0 </span>
                <span
                  ><svg
                    width="11"
                    height="11"
                    viewBox="0 0 11 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.9535 0.985463C5.1297 0.590213 5.6907 0.590213 5.8668 0.985463L6.9503 3.41609C7.023 3.57909 7.177 3.69099 7.3545 3.70979L10.001 3.98909C10.4313 4.03449 10.6047 4.56809 10.2832 4.85779L8.3064 6.63929C8.1738 6.75879 8.1149 6.93989 8.152 7.11449L8.7041 9.71769C8.7939 10.1411 8.3401 10.4708 7.9652 10.2546L5.66 8.92499C5.5054 8.83579 5.3149 8.83579 5.1603 8.92499L2.8551 10.2546C2.4803 10.4708 2.0264 10.1411 2.1162 9.71769L2.6683 7.11449C2.7054 6.93989 2.6465 6.75879 2.5139 6.63929L0.537099 4.85779C0.215599 4.56809 0.388998 4.03449 0.819298 3.98909L3.4658 3.70979C3.6433 3.69099 3.7973 3.57909 3.87 3.41609L4.9535 0.985463Z"
                      fill="#32D583"
                    />
                  </svg>
                </span>
              </span>
            </div>
            <p
              class="mt-[1rem] opacity-[0.6000000238418579] md:text-[15px] text-[14px] leading-[151%]"
            >
              I've been using EasyWin for several months now, and I can
              confidently say that their accuracy is unmatched. The tips and
              predictions they provide are always backed by solid data and
              analysis, and I have consistently seen positive results. I highly
              recommend EasyWin to anyone looking to improve their betting
              strategy and earn more money.
            </p>
            <div class="flex items-center justify-end">
              <div class=""></div>
            </div>
          </div>
          <div
            class="flex flex-col w-full lg:max-w-[48%] rounded-[16px] bg-[#1A1D34] border-[1px] border-[#1A1D34] p-[1rem] md:p-[2rem]"
          >
            <div class="flex w-full items-center justify-between">
              <h3
                class="max-w-[354px] font-semibold md:text-[19px] md:leading-[28px] text-[17px]"
              >
                Consistent Performance: EasyWin Delivers Results Every Time
              </h3>
              <span
                class="bg-white rounded-[6px] p-[5px] gap-[3px] py-[2px] flex items-center text-[#1F2937]"
              >
                <span> 5.0 </span>
                <span
                  ><svg
                    width="11"
                    height="11"
                    viewBox="0 0 11 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.9535 0.985463C5.1297 0.590213 5.6907 0.590213 5.8668 0.985463L6.9503 3.41609C7.023 3.57909 7.177 3.69099 7.3545 3.70979L10.001 3.98909C10.4313 4.03449 10.6047 4.56809 10.2832 4.85779L8.3064 6.63929C8.1738 6.75879 8.1149 6.93989 8.152 7.11449L8.7041 9.71769C8.7939 10.1411 8.3401 10.4708 7.9652 10.2546L5.66 8.92499C5.5054 8.83579 5.3149 8.83579 5.1603 8.92499L2.8551 10.2546C2.4803 10.4708 2.0264 10.1411 2.1162 9.71769L2.6683 7.11449C2.7054 6.93989 2.6465 6.75879 2.5139 6.63929L0.537099 4.85779C0.215599 4.56809 0.388998 4.03449 0.819298 3.98909L3.4658 3.70979C3.6433 3.69099 3.7973 3.57909 3.87 3.41609L4.9535 0.985463Z"
                      fill="#32D583"
                    />
                  </svg>
                </span>
              </span>
            </div>
            <p
              class="mt-[1rem] opacity-[0.6000000238418579] md:text-[15px] text-[14px] leading-[151%]"
            >
              EasyWin is the most impressive platform for football tips and
              predictions that I have come across. Their analysis and insights
              are top-notch, and I have seen a significant improvement in my
              betting strategy since I started using their platform. I highly
              recommend EasyWin to anyone looking to take their football betting
              experience to the next level.
            </p>
            <div class="flex items-center justify-end">
              <div class=""></div>
            </div>
          </div>
        </div>
      </section>
      <section class="bg-[#040718]">
        <div
          class="p-[1rem] mx-auto lg:max-w-[1700px] flex items-center justify-center lg:flex-row flex-col-reverse gap-[1rem] py-[3rem]"
        >
          <div
            class="lg:max-w-[909px] w-full flex items-center lg:items-center flex-col justify-center"
          >
            <h3
              class="inline-block text-white text-center font-polysans font-semibold md:text-[32px] md:leading-[44.29px] text-[24px] leading-[33.22px]"
            >
              <span class="text-[#F0CD0A]">Win Big</span> with EasyWin: Accurate
              Football Tips and Predictions
            </h3>
            <p
              class="text-[#FFFFFF] text-center mt-[1.5rem] md:text-[16px] md:leading-[19.2px] text-[14px] leading-[16.8px] font-polysans opacity-[0.7]"
            >
              Whether you are a seasoned bettor or a newbie, EasyWin is the
              ultimate platform to improve your betting strategy. We provide you
              with comprehensive analysis and tips for the most popular leagues
              and cups, including the Premier League, La Liga, Serie A,
              Bundesliga, UEFA Champions League, and more.
            </p>
            <button
              class="mt-[1.5rem] font-inter font-medium text-[16px] p-[12px] rounded-[8px] leading-[24px] bg-white text-[#003D66]"
            >
              Get started
            </button>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import moment from "moment-timezone";

import Header from "../../components/header/index.vue";
import Footer from "../../components/footer/index.vue";
export default {
  name: "Home",
  components: { Header, Footer },
  data() {
    return {
      activeSelection: "today",
      availablePredictions: [
        "Over 1.5 Goals",
        "Double Chance",
        "Over/Under 2.5 Goals",
        "BTTS/GG",
        "Match Corners",
        "Draw No Bet",
        "Over 0.5 HT",
        "Handicap",
      ],

      predictions: [
        {
          time: "12:00 PM",
          sport: "Football",
          country: "England",
          league: "Premier League",
          fixture: "Manchester United vs Chelsea",
          tip: "1X",
          bookmaker: "William Hill",
        },
        {
          time: "3:00 PM",
          sport: "Basketball",
          country: "USA",
          league: "NBA",
          fixture: "Los Angeles Lakers vs Brooklyn Nets",
          tip: "Over 215.5",
          bookmaker: "Bet365",
        },
        {
          time: "6:00 PM",
          sport: "Tennis",
          country: "Spain",
          league: "ATP Barcelona",
          fixture: "Rafael Nadal vs Novak Djokovic",
          tip: "Nadal to win",
          bookmaker: "888sport",
        },
        {
          time: "9:00 PM",
          sport: "Hockey",
          country: "Canada",
          league: "NHL",
          fixture: "Toronto Maple Leafs vs Montreal Canadiens",
          tip: "Under 5.5",
          bookmaker: "Betway",
        },
      ],
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      tips: (state) => state.predictions.freeTips,
      bookingCodes: (state) => state.predictions.freeBookingCodes,
      isGettingTips: (state) => state.predictions.isGettingTips,
      isLoggedIn: (state) => state.auth.isLoggedIn,
      bookiesLogo: (state) => state.predictions.bookiesLogo,
    }),
    computedFreeTips() {
      return {
        yesterday: this.tips?.yesterday?.tips ?? [],
        today: this.tips?.today?.tips ?? [],
        tomorrow: this.tips?.tomorrow?.tips ?? [],
      };
    },
    isCurrentSelectionEmpty() {
      return (
        !this.tips ||
        !this.tips[this.activeSelection] ||
        !this.tips[this.activeSelection] ||
        !this.tips[this.activeSelection]?.tips ||
        !this.tips[this.activeSelection]?.tips?.length
      );
    },
  },
  methods: {
    getLeagueAbbreviation(leagueName) {
      const words = leagueName.split(" ");

      // Get the first letter of each word in the league name
      const abbreviation = words.reduce((abbr, word) => {
        if (word.length > 0) {
          abbr += word[0].toUpperCase();
        }
        return abbr;
      }, "");

      // Return the first three letters of the abbreviation or the full abbreviation if it's less than three letters
      return abbreviation.substring(0, 3);
    },
    ...mapActions({
      getTips: "predictions/getFreeTips",
      getBookingCodes: "predictions/getFreeBookingCodes",
    }),
    generateLinkFromBookmaker(bookmaker) {
      return bookmaker?.signupLink
        ? bookmaker.signupLink
        : bookmaker?.name?.toLowerCase() === "1xbet"
        ? "https://1xbet.com/"
        : bookmaker?.name?.toLowerCase() === "zebet"
        ? "https://zebet.ng/"
        : "https://1xbet.com/";
    },
    generateLogoFromBookmaker(bookmaker) {
      return bookmaker?.logo
        ? bookmaker.logo
        : bookmaker?.name?.toLowerCase() === "1xbet"
        ? this.bookiesLogo["1xbet"]
        : bookmaker?.name?.toLowerCase() === "zebet"
        ? this.bookiesLogo["zebet"]
        : this.bookiesLogo["1xbet"];
    },
    convertToWATTime(utcTime) {
      const getDayOfMonthSuffix = (day) => {
        if (day >= 11 && day <= 13) {
          return "th";
        }
        switch (day % 10) {
          case 1:
            return "st";
          case 2:
            return "nd";
          case 3:
            return "rd";
          default:
            return "th";
        }
      };

      const utcMoment = moment.utc(utcTime);
      const watMoment = utcMoment.tz("Africa/Lagos");
      const dayOfMonth = watMoment.format("DD");
      const suffix = getDayOfMonthSuffix(parseInt(dayOfMonth));
      const time = watMoment.format("h:mm A");
      return `${time}`;
    },

    toggleAccordion(index) {
      this.faqItems[index].isOpen = !this.faqItems[index].isOpen;
    },
    handleSelectionChange(selection) {
      if (this.activeSelection === selection) return;
      this.activeSelection = selection;
    },
  },
  watch: {
    user: {
      handler() {
        if (this.user && this.isLoggedIn && !this.tips) {
          this.getTips();
        }
        if (this.user && this.isLoggedIn && !this.bookingCodes) {
          this.getBookingCodes();
        }
      },
      immediate: true,
      deep: true,
    },
  },

  mounted() {
    if (!this.tips) {
      this.getTips();
    }
    if (!this.bookingCodes.length) {
      this.getBookingCodes();
    }
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
