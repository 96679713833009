import axios from "../../service/axios";

export default {
  namespaced: true,
  state: {
    user: null,
    isLoggedIn: false,
    isGettingUser: false,
    isMadeInitialAuthCall: false,
  },
  getters: {},
  mutations: {
    updateUser(state, payload) {
      state.user = { ...state.user, ...payload };
    },
  },
  actions: {
    async getUserDetails({ commit, state }) {
      // eslint-disable-next-line
      return new Promise(async (resolve, reject) => {
        if (!state.isMadeInitialAuthCall) {
          state.isMadeInitialAuthCall = true;
        }
        try {
          state.isGettingUser = true;

          const response = await axios.get("/user");

          state.isGettingUser = false;

          if (response.data.status === "success") {
            state.user = response.data.data.user ?? null;

            state.isLoggedIn = true;
          }

          return resolve(response.data);
        } catch (err) {
          state.isGettingUser = false;

          return reject(err);
        }
      });
    },
    async logout() {
      try {
        const { data } = await axios.get("/auth/logout");
        if (data.status === "success") {
          localStorage.clear();
          window.location.href = "/";
        }
      } catch (e) {
        return e;
      }
    },
  },
};
