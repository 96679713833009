import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueMeta from "vue-meta";
import Checkbox from "./components/checkbox";
import VuePhoneNumberInput from "vue-phone-number-input";
import Loader from "./components/loader";
import axios from "./service/axios";
import VueAxios from "vue-axios";
import VuePassword from "vue-password";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import VueLetterAvatar from "vue-letter-avatar";
import VueTippy, { TippyComponent } from "vue-tippy";
import Clipboard from "v-clipboard";
import AOS from "aos";
import "aos/dist/aos.css";

import "vue-phone-number-input/dist/vue-phone-number-input.css";
import "tippy.js/themes/light.css";
import "tippy.js/themes/light-border.css";
import "tippy.js/themes/google.css";
import "tippy.js/themes/translucent.css";

import VueToast from "vue-toast-notification";
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import "vue-toast-notification/dist/theme-bootstrap.css";

Vue.config.productionTip = false;

Vue.component("Checkbox", Checkbox);
Vue.component("vue-phone-number-input", VuePhoneNumberInput);
Vue.component("Loader", Loader);
Vue.component("v-select", vSelect);
Vue.component("VuePassword", VuePassword);
Vue.use(VueTippy);
Vue.component("tippy", TippyComponent);
Vue.use(VueAxios, axios);
Vue.use(VueLetterAvatar);
Vue.use(VueToast);
Vue.use(Clipboard);

Vue.use(VueMeta);
new Vue({
  router,
  store,
  render: (h) => h(App),
  mounted() {
    AOS.init();
  },
}).$mount("#app");
