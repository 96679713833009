<template>
  <label class="custom-checkbox">
    <input
      type="checkbox"
      :checked="checked"
      @change="handleChange"
      @input="handleInput"
    />
    <span class="checkmark"></span>
    <!-- Check me -->
  </label>
</template>
<script>
export default {
  name: "Checkbox",
  metaInfo: {},
  components: {},
  data() {
    return {};
  },
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
  methods: {
    handleChange(e) {
      this.$emit("change", e.target.checked);
    },
    handleInput(e) {
      this.$emit("input", e.target.checked);
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
