var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sticky top-0 z-[10]"},[_c('header',{staticClass:"sticky w-full bg-[#060A23] text-white border-b-[1px] border-[#6670851f] top-0 z-[10]"},[_c('nav',{staticClass:"p-[1rem] flex items-center justify-between mx-auto max-w-[1700px]"},[_c('div',{staticClass:"nav-left flex items-center"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("@/assets/images/logo.svg"),"alt":"logo"}})]),_c('ul',{staticClass:"md:flex hidden flex-row items-center ml-[2rem]"},[_c('li',{},[_c('router-link',{staticClass:"font-medium text-[16px] leading-[24px] font-inter p-[10px] rounded-[5px] text-white",attrs:{"to":{
                name: 'home',
              }}},[_vm._v("Home")])],1),_c('li',{},[_c('router-link',{staticClass:"font-medium text-[16px] leading-[24px] font-inter p-[10px] rounded-[5px] text-white",attrs:{"to":{
                name: 'pricing',
              }}},[_vm._v("Subscription")])],1),_c('li',{staticClass:"ml-[1rem]"},[_c('router-link',{staticClass:"font-medium text-[16px] leading-[24px] font-inter p-[10px] rounded-[5px] text-white",attrs:{"to":{
                name: 'about',
              }}},[_vm._v("About us")])],1)])],1),(!_vm.isLoggedIn)?_c('div',{staticClass:"nav-right md:flex hidden items-center gap-[20px]"},[_c('router-link',{staticClass:"login-btn font-inter font-medium text-[16px] leading-[24px] text-white",attrs:{"to":{ name: 'login' }}},[_vm._v(" Login ")]),_c('router-link',{staticClass:"signup-btn font-inter font-medium text-[16px] leading-[24px] text-white bg-[#32D583] rounded-[8px] px-[18px] py-[10px]",attrs:{"to":{ name: 'register' }}},[_vm._v(" Get Started ")])],1):_c('router-link',{staticClass:"nav-right md:flex hidden font-inter font-medium text-[16px] leading-[24px] text-white bg-[#32D583] rounded-[8px] px-[18px] py-[10px]",attrs:{"to":{ name: 'dashboard' }}},[_vm._v(" Go to dashboard ")]),_c('button',{staticClass:"md:hidden block ml-[10px] pointer",on:{"click":function($event){return _vm.toggleSidebar()}}},[_c('svg',{attrs:{"width":"20","height":"15","viewBox":"0 0 20 15","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M1 7.5H15M1 1.5H19M1 13.5H19","stroke":"#667085","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])])],1)]),_c('nav-aside',{attrs:{"aside-class":"!bg-[#060A23]"}},[_c('div',{staticClass:"p-[1rem] font-inter flex flex-col text-white overflow-auto"},[_c('router-link',{staticClass:"active flex items-center link-item p-[1rem] rounded-[12px]",attrs:{"to":{
          name: 'home',
        }}},[_c('span',{staticClass:"ml-[10px]"},[_vm._v(" Home")])]),_c('router-link',{staticClass:"flex items-center link-item p-[1rem] rounded-[12px]",attrs:{"to":{ name: 'pricing' }}},[_c('span',{staticClass:"ml-[10px]"},[_vm._v(" Subscription")])]),_c('router-link',{staticClass:"flex items-center link-item p-[1rem] rounded-[12px]",attrs:{"to":{ name: 'about' }}},[_c('span',{staticClass:"ml-[10px]"},[_vm._v("About Us")])]),(!_vm.isLoggedIn)?_c('div',{staticClass:"mt-[12rem]"},[_c('router-link',{staticClass:"flex items-center link-item p-[1rem] rounded-[12px]",attrs:{"to":{ name: 'login' }}},[_c('span',{staticClass:"ml-[10px]"},[_vm._v("Login")])]),_c('router-link',{staticClass:"flex items-center link-item p-[1rem] rounded-[12px]",attrs:{"to":{ name: 'register' }}},[_c('span',{staticClass:"ml-[10px]"},[_vm._v("Signup")])])],1):_c('router-link',{staticClass:"nav-right mt-[12rem] font-inter font-medium text-[16px] leading-[24px] text-white bg-[#32D583] rounded-[8px] px-[18px] py-[10px] text-center",attrs:{"to":{ name: 'dashboard' }}},[_vm._v(" Go to dashboard ")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }