import Vue from "vue";
import Vuex from "vuex";
import dashboard from "./dashboard";
import auth from "./auth";
import predictions from "./predictions";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    dashboard,
    auth,
    predictions,
  },
});
