import axios from "../../service/axios";

export default {
  namespaced: true,
  state: {
    tips: null,
    isGettingTips: false,
    freeBookingCodes: [],
    bookingCodes: [],
    isGettingBookingCodes: false,
    currentBookingCodesPage: 1,
    currentTipsPage: 1,
    freeTips: null,
    totalTipsPage: 1,
    totalBookingCodesPage: 1,
    bookingCodesData: null,
    tipsData: null,
    bookiesLogo: {
      "1xbet":
        "https://ik.imagekit.io/awiabnkd6/1xbet-logo.svg?updatedAt=1687929672282",
      zebet:
        "https://ik.imagekit.io/awiabnkd6/zebet-logo.svg?updatedAt=1687929740545",
    },
  },
  getters: {},
  mutations: {
    setCurrentTipsPage(state, payload) {
      state.currentTipsPage = payload;
    },
    setCurrentBookingCodesPage(state, payload) {
      state.currentBookingCodesPage = payload;
    },
  },
  actions: {
    async getTips({ state, rootState }, payload) {
      if (state.isGettingTips) return;
      try {
        state.isGettingTips = true;
        // const { plan } = rootState.auth.user;

        // if (!plan) return;

        // const url = plan === "free" ? "/tips/free" : "/tips";
        const url = `/tips?page=${payload?.page ?? 1}`;

        const { data } = await axios.get(url);

        state.isGettingTips = false;
        if (data.status === "success") {
          if (payload?.reset) {
            state.currentTipsPage = 1;
          }
          if (payload?.page) {
            state.currentTipsPage = payload.page;
          }

          const newTips = payload?.reset
            ? data?.data?.tips
            : {
                yesterday: {
                  ...(data?.data?.tips?.yesterday ?? {}),
                  tips: [
                    ...(state?.tips?.yesterday?.tips ?? []),
                    ...(data?.data?.tips?.yesterday?.tips ?? []),
                  ],
                },
                today: {
                  ...(data?.data?.tips?.today ?? {}),
                  tips: [
                    ...(state?.tips?.today?.tips ?? []),
                    ...(data?.data?.tips?.today?.tips ?? []),
                  ],
                },
                tomorrow: {
                  ...(data?.data?.tips?.tomorrow ?? {}),
                  tips: [
                    ...(state?.tips?.tomorrow?.tips ?? []),
                    ...(data?.data?.tips?.tomorrow?.tips ?? []),
                  ],
                },
              } ??
              state.tips ??
              null;

          state.tips = newTips;
        }
        state.isGettingTips = false;
      } catch (err) {
        state.isGettingTips = false;
        throw err;
      }
    },
    async getBookingCodes({ state, rootState }, payload) {
      if (state.isGettingBookingCodes) return;
      try {
        state.isGettingBookingCodes = true;
        const { plan } = rootState.auth.user;

        if (!plan) return;

        // const url = plan === "free" ? "/tips/free" : "/tips";
        const url = `/codes?page=${payload?.page ?? 1}`;

        const { data } = await axios.get(url);
        state.isGettingBookingCodes = false;
        if (data.status === "success") {
          state.bookingCodesData = data?.data;

          if (payload?.page) {
            state.currentBookingCodesPage = payload.page;
          }

          if (payload?.reset) {
            state.currentBookingCodesPage = 1;
            state.bookingCodes = data?.data?.bookingCodes;
            return;
          }

          const newBookingCodes = [
            ...state?.bookingCodes,
            ...data?.data?.bookingCodes,
          ];

          state.bookingCodes = newBookingCodes;
        }
      } catch (err) {
        state.isGettingBookingCodes = false;
        throw err;
      }
    },

    async getFreeTips({ state, rootState }, payload) {
      if (state.isGettingTips) return;
      try {
        state.isGettingTips = true;

        const url = "/tips/free";

        const { data } = await axios.get(url);
        state.isGettingTips = false;
        if (data.status === "success") {
          state.freeTips = payload?.reset
            ? data?.data?.tips
            : {
                yesterday: {
                  ...(data?.data?.tips?.yesterday ?? {}),
                  tips: [
                    ...(state?.freeTips?.yesterday?.tips ?? []),
                    ...(data?.data?.tips?.yesterday?.tips ?? []),
                  ],
                },
                today: {
                  ...(data?.data?.tips?.today ?? {}),
                  tips: [
                    ...(state?.freeTips?.today?.tips ?? []),
                    ...(data?.data?.tips?.today?.tips ?? []),
                  ],
                },
                tomorrow: {
                  ...(data?.data?.tips?.tomorrow ?? {}),
                  tips: [
                    ...(state?.freeTips?.tomorrow?.tips ?? []),
                    ...(data?.data?.tips?.tomorrow?.tips ?? []),
                  ],
                },
              } ??
              state.freeTips ??
              null;
        }
        state.isGettingTips = false;
      } catch (err) {
        state.isGettingTips = false;
        throw err;
      }
    },
    async getFreeBookingCodes({ state }) {
      if (state.isGettingBookingCodes) return;
      try {
        state.isGettingBookingCodes = true;

        const url = "/codes/free";

        const { data } = await axios.get(url);
        state.isGettingBookingCodes = false;
        if (data.status === "success") {
          state.freeBookingCodes = data?.data?.bookingCodes ?? [];
        }
        state.isGettingBookingCodes = false;
      } catch (err) {
        state.isGettingBookingCodes = false;
        throw err;
      }
    },
  },
};
