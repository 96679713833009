import axios from "../../service/axios";
import Axios from "axios";
export default {
  namespaced: true,
  state: {
    isSidebarOpen: false,
    banks: [],
    isGettingBanks: false,
    isResolvingAccount: false,
    resolveCancelToken: null,
    transactions: [],
    isFetchingTransactions: false,
    currentTransactionsPage: 1,
    totalTransactionsPage: 0,
  },
  getters: {},
  mutations: {
    toggleSidebar(state, payload) {
      if (payload !== undefined) {
        state.isSidebarOpen = payload;
        return;
      }
      state.isSidebarOpen = !state.isSidebarOpen;
    },
  },
  actions: {
    async getBanks({ _, state }) {
      if (state.isGettingBanks || state.banks.length > 0) return;
      try {
        state.isGettingBanks = false;
        const { data } = await axios.get("/payments/banks");
        state.isGettingBanks = true;

        if (data.status === "success") {
          state.banks = data?.data?.banks ?? [];
        }

        state.isGettingBanks = true;
      } catch (err) {
        state.isGettingBanks = false;
        throw err;
      }
    },
    getTransactions: async ({ state }, payload) => {
      if (state.isFetchingTransactions) return;
      state.isFetchingTransactions = true;
      try {
        const { data } = await axios.get("/user/transactions", {
          params: payload,
        });
        state.isFetchingTransactions = false;
        if (data.status === "success") {
          state.transactions = [
            ...state.transactions,
            ...(data?.data?.transactions ?? []),
          ];
        }
      } catch (err) {
        state.isFetchingTransactions = false;
        throw err;
      }
    },
    resolveAccount({ state }, payload) {
      state.isResolvingAccount = true;
      if (state.resolveCancelToken) {
        state.resolveCancelToken.cancel();
      }
      state.resolveCancelToken = Axios.CancelToken.source();

      axios
        .post("/payments/resolve", payload, {
          cancelToken: state.resolveCancelToken.token,
        })
        .then((res) => {
          state.isResolvingAccount = false;

          if (res.data.status === "success") {
            return res.data.data;
          }
          throw new Error(res.data.message);
        })
        .catch((err) => {
          if (Axios.isCancel(err)) return;
          state.isResolvingAccount = false;
          throw err;
        });
    },
  },
};
