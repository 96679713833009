import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../pages/home/index.vue";
import Store from "../store/index.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../pages/about/index.vue"),
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import("../pages/terms/index.vue"),
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () => import("../pages/privacy-policy/index.vue"),
  },
  {
    path: "/pricing",
    name: "pricing",
    component: () => import("../pages/pricing/index.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../pages/login/index.vue"),
    meta: {
      guestOnly: true,
    },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../pages/register/index.vue"),
    meta: {
      guestOnly: true,
    },
  },
  {
    path: "/faq",
    name: "faq",
    component: () => import("../pages/faq/index.vue"),
  },
  {
    path: "/reset-password/:selector/:token",
    name: "reset-password",
    component: () => import("../pages/reset-password/index.vue"),
  },
  {
    path: "/reset-password-request",
    name: "reset-password-request",
    component: () => import("../pages/reset-password-request/index.vue"),
  },

  {
    path: "/dashboard",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../layout/dashboard/index.vue"),
    children: [
      {
        path: "",
        name: "dashboard",
        component: () => import("../pages/dashboard/overview"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/notifications",
        name: "dashboard-notifications",
        component: () => import("../pages/dashboard/notifications"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/settings",
        name: "dashboard-settings",
        component: () => import("../pages/dashboard/settings"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/livescores",
        name: "dashboard-livescores",
        component: () => import("../pages/livescores/index.vue"),
      },
      {
        path: "/predictions",
        name: "dashboard-predictions",
        component: () => import("../pages/dashboard/predictions"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/admin",
        name: "dashboard-mailer",
        component: () => import("../pages/dashboard/admin-mailer"),
        meta: {
          requiresAuth: true,
          adminOnly: true,
        },
      },
    ],
  },
  {
    path: "/*",
    name: "not-found",
    component: () => import("../pages/404/index.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const logout = async (next, to) => {
  document.cookie =
    "connect.sid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

  return next({ name: "login", query: { redirect: to.fullPath } });
};

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (Store.state.auth.user && Store.state.auth.isLoggedIn) {
      if (
        to.matched.some((record) => record.meta.adminOnly) &&
        !Store.state.auth.user.isAdmin
      )
        return next({ name: "dashboard" });

      return next();
    } else {
      const cookie = document.cookie.split(";").find((cookie) => {
        return cookie.trim().startsWith("connect.sid=");
      });

      if (!cookie)
        return next({ name: "login", query: { redirect: to.fullPath } });

      try {
        if (Store.state.auth.isMadeInitialAuthCall)
          return next({
            name: "login",
          });
        const data = await Store.dispatch("auth/getUserDetails");

        if (data.status === "success") {
          if (
            to.matched.some((record) => record.meta.adminOnly) &&
            !Store.state.auth.user.isAdmin
          )
            return next({ name: "dashboard" });
          return next();
        } else {
          logout(next, to);
        }
      } catch (err) {
        // remove cookie
        logout(next, to);
      }
    }
  } else if (to.matched.some((record) => record.meta.guestOnly)) {
    if (Store.state.auth.isMadeInitialAuthCall) {
      if (Store.state.auth.user && Store.state.auth.isLoggedIn)
        return next({ name: "dashboard" });
      return next();
    }
    if (Store.state.auth.user && Store.state.auth.isLoggedIn) {
      return next({ name: "dashboard" });
    } else {
      try {
        const data = await Store.dispatch("auth/getUserDetails");

        if (data.status === "success") {
          return next({
            name: "dashboard",
          });
        } else {
          return next();
        }
      } catch (err) {
        // remove cookie
        return next();
      }
    }
  } else {
    if (Store.state.auth.isMadeInitialAuthCall) return next();
    if (Store.state.auth.user && Store.state.auth.isLoggedIn) {
      return next();
    }
    const cookie = document.cookie.split(";").find((cookie) => {
      return cookie.trim().startsWith("connect.sid=");
    });

    if (!cookie) return next();
    Store.dispatch("auth/getUserDetails");
    return next();
  }
});

export default router;
