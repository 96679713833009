<template>
  <transition name="fade">
    <router-view
      v-if="
        isGettingUser === false ||
        ['home', 'about', 'not-found'].includes($route.name)
      "
    />
    <div
      v-else
      class="absolute w-full h-full left-0 right-0 top-0 bottom-0 flex items-center justify-center"
    >
      <lottie :options="defaultOptions" :height="100" :width="100" />
    </div>
    <!-- <div v-if="show">This element will fade in and out.</div> -->
  </transition>
</template>
<style lang="scss">
@import "./App.scss";
</style>
<script>
import { mapState } from "vuex";
import Lottie from "vue-lottie/src/lottie.vue";
import * as animationData from "./assets/lottie/app-loader.json";

export default {
  name: "App",
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Easy win",
    // all titles will be injected into this template
    // titleTemplate: "%s | My Awesome Webapp",
  },
  components: { Lottie },
  data() {
    return {
      defaultOptions: { animationData: animationData },
      animationSpeed: 1,
    };
  },
  computed: {
    ...mapState({
      isGettingUser: (state) => state.auth.isGettingUser,
    }),
  },

  watch: {
    $route: {
      handler() {},
      deep: true,
    },
  },
};
</script>
