<template>
  <div class="sticky top-0 z-[10]">
    <header
      class="sticky w-full bg-[#060A23] text-white border-b-[1px] border-[#6670851f] top-0 z-[10]"
    >
      <nav
        class="p-[1rem] flex items-center justify-between mx-auto max-w-[1700px]"
      >
        <div class="nav-left flex items-center">
          <router-link to="/">
            <img src="@/assets/images/logo.svg" alt="logo" />
          </router-link>
          <ul class="md:flex hidden flex-row items-center ml-[2rem]">
            <li class="">
              <router-link
                :to="{
                  name: 'home',
                }"
                class="font-medium text-[16px] leading-[24px] font-inter p-[10px] rounded-[5px] text-white"
                >Home</router-link
              >
            </li>
            <li class="">
              <router-link
                :to="{
                  name: 'pricing',
                }"
                class="font-medium text-[16px] leading-[24px] font-inter p-[10px] rounded-[5px] text-white"
                >Subscription</router-link
              >
            </li>
            <!-- <li class="">
              <router-link
                :to="{
                  name: 'faq',
                }"
                class="font-medium text-[16px] leading-[24px] font-inter p-[10px] rounded-[5px] text-white"
                >Faq</router-link
              >
            </li> -->
            <li class="ml-[1rem]">
              <router-link
                :to="{
                  name: 'about',
                }"
                class="font-medium text-[16px] leading-[24px] font-inter p-[10px] rounded-[5px] text-white"
                >About us</router-link
              >
            </li>
            <!-- <li class="ml-[1rem]">
              <router-link
                :to="{
                  name: 'dashboard-predictions',
                }"
                class="font-medium text-[16px] leading-[24px] font-inter p-[10px] rounded-[5px] text-white"
                >Contact</router-link
              >
            </li> -->
          </ul>
        </div>

        <div
          class="nav-right md:flex hidden items-center gap-[20px]"
          v-if="!isLoggedIn"
        >
          <router-link
            :to="{ name: 'login' }"
            class="login-btn font-inter font-medium text-[16px] leading-[24px] text-white"
          >
            Login
          </router-link>
          <router-link
            :to="{ name: 'register' }"
            class="signup-btn font-inter font-medium text-[16px] leading-[24px] text-white bg-[#32D583] rounded-[8px] px-[18px] py-[10px]"
          >
            Get Started
          </router-link>
        </div>
        <router-link
          :to="{ name: 'dashboard' }"
          v-else
          class="nav-right md:flex hidden font-inter font-medium text-[16px] leading-[24px] text-white bg-[#32D583] rounded-[8px] px-[18px] py-[10px]"
        >
          Go to dashboard
        </router-link>

        <button
          class="md:hidden block ml-[10px] pointer"
          @click="toggleSidebar()"
        >
          <svg
            width="20"
            height="15"
            viewBox="0 0 20 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 7.5H15M1 1.5H19M1 13.5H19"
              stroke="#667085"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
        </button>
      </nav>
    </header>
    <nav-aside aside-class="!bg-[#060A23]">
      <div class="p-[1rem] font-inter flex flex-col text-white overflow-auto">
        <router-link
          class="active flex items-center link-item p-[1rem] rounded-[12px]"
          :to="{
            name: 'home',
          }"
          ><span class="ml-[10px]"> Home</span></router-link
        >
        <router-link
          class="flex items-center link-item p-[1rem] rounded-[12px]"
          :to="{ name: 'pricing' }"
          ><span class="ml-[10px]"> Subscription</span></router-link
        >
        <!-- <router-link
          class="flex items-center link-item p-[1rem] rounded-[12px]"
          :to="{ name: 'faq' }"
          ><span class="ml-[10px]">Faq</span></router-link
        > -->
        <router-link
          class="flex items-center link-item p-[1rem] rounded-[12px]"
          :to="{ name: 'about' }"
          ><span class="ml-[10px]">About Us</span></router-link
        >
        <!-- <router-link
          class="flex items-center link-item p-[1rem] rounded-[12px]"
          :to="{ name: 'dashboard-predictions' }"
          ><span class="ml-[10px]">Contact Us</span></router-link
        > -->
        <div v-if="!isLoggedIn" class="mt-[12rem]">
          <router-link
            :to="{ name: 'login' }"
            class="flex items-center link-item p-[1rem] rounded-[12px]"
            ><span class="ml-[10px]">Login</span></router-link
          >
          <router-link
            :to="{ name: 'register' }"
            class="flex items-center link-item p-[1rem] rounded-[12px]"
            ><span class="ml-[10px]">Signup</span></router-link
          >
        </div>
        <router-link
          v-else
          :to="{ name: 'dashboard' }"
          class="nav-right mt-[12rem] font-inter font-medium text-[16px] leading-[24px] text-white bg-[#32D583] rounded-[8px] px-[18px] py-[10px] text-center"
        >
          Go to dashboard
        </router-link>
      </div>
    </nav-aside>
  </div>
</template>
<style lang="scss" scoped>
@import "./index.scss";
</style>
<script>
import { mapMutations, mapState } from "vuex";
import NavAside from "../nav-aside/index.vue";

export default {
  name: "Header",
  data() {
    return {
      isLoading: false,
    };
  },
  components: {
    NavAside,
  },
  computed: {
    ...mapState({
      isSidebarOpen: (state) => state.dashboard.isSidebarOpen,
      isLoggedIn: (state) => state.auth.isLoggedIn,
    }),
  },
  methods: {
    ...mapMutations("dashboard", ["toggleSidebar"]),
  },
  watch: {},
};
</script>
